<template>
  <CCard>
    <CCardHeader>
      <slot name="header">
        <CIcon name="cil-https"/> {{caption}}
        <div class="card-header-actions">
          <CButton color="info" size="sm" @click="storeProductModal()">Nuevo Producto</CButton>
          <CButton color="success" size="sm" @click="importModal()">Importar Productos</CButton>
        </div>
      </slot>
    </CCardHeader>
    <CCardBody>
      <CDataTable
        :hover="hover"
        :striped="striped"
        :border="border"
        :small="small"
        :fixed="fixed"
        :items="items"
        :fields="fields"
        :items-per-page="small ? 10 : 5"
        :dark="dark"
        pagination
        v-if="items.length > 0"
      >
        <template #product_code="{item}"><td class="w200 text-center"><span>{{item.product_code}}</span></td></template>
        <template #files="{item}"><td class="text-left">
          <span>
            <ul style="margin: 0px"><li v-for="file in item.files"><a :href="file.answer" target="_blank">{{file.question}}</a></li></ul>
          </span>
        </td></template>
        <template #actions="{item}">
          <td class="table_actions b1">
            <CButton color="warning" size="sm" @click="updateProductModal(item)"><CIcon name="cil-pencil"/></CButton>
            <CButton color="danger" size="sm" @click="deleteModal(item)"><CIcon name="cil-trash"/></CButton>
          </td>
        </template>
      </CDataTable>
      <div style="width: 100%; text-align: center;" v-if="items.length == 0"><br />AÚN NO SE HAN REGISTRADO PRODUCTOS PARTICIPANTES A ESTE CONCURSO<br /><br /></div>
    </CCardBody>
    <DeleteModal ref="deleteModal" @delete="deleteProcess"></DeleteModal>
    <ImportProductsModal ref="importModal" @import="importProcess"></ImportProductsModal>
    <ChallengeProductModal ref="productModal" @store="storeProcess" @update="updateProcess"></ChallengeProductModal>
  </CCard>
</template>

<script>
import DeleteModal from '../global/DeleteModal.vue'
import ImportProductsModal from './ImportProductsModal.vue'
import ChallengeProductModal from './ChallengeProductModal.vue'
import ws from '../../services/challenges';
import store from '../../store'
import router from '../../router/index'

export default {
  name: 'ChallengeProducts',
  components: { DeleteModal, ImportProductsModal, ChallengeProductModal },
  props: {
    items: Array,
    permissions: Array,
    fields: {
      type: Array,
      default () {
        return [
          {key: 'product_code', label: 'Código'},
          {key: 'product_name', label: 'Nombre'},
          {key: 'actions', label: 'Acciones', class: 'actions' },
        ]
      }
    },
    caption: {
      type: String,
      default: 'Table'
    },
    challenge: {
      type: Object,
      default: 0
    },
    hover: Boolean,
    striped: Boolean,
    border: Boolean,
    small: Boolean,
    fixed: Boolean,
    dark: Boolean
  },
  data() {
    return {
      toasts: [],
    }
  },
  methods: {
    importModal () {
      this.$refs.importModal.importModal(this.challenge.id);
    },
    async importProcess(data) {
      this.$emit("refresh", data );
    },
    storeProductModal () {
      this.$refs.productModal.storeProductModal(this.challenge.id);
    },
    updateProductModal (item) {
      this.$refs.productModal.updateProductModal(this.challenge.id, item);
    },
    async storeProcess(data) {
      let response = await ws.storeProduct(data);
      
      if(response.type == "success"){
        this.$emit("refresh", response.data );
      }

      this.showToast(response.type, response.message);
    },
    async updateProcess(data) {
      let response = await ws.updateProduct(data);
      console.log(data);

      if(response.type == "success"){
        this.$emit("refresh", response.data );
      }

      this.showToast(response.type, response.message);
    },
    deleteModal (item) {
      this.$refs.deleteModal.show("Eliminar Producto", item.id, item.supplier_name);
    },
    async deleteProcess(id) {
      let response = await ws.deleteProduct({id: id});
      
      if(response.type == "success"){
        this.$emit("refresh", response.data );
      }

      this.showToast(response.type, response.message);
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    },
    slugify(str) {
      return String(str)
        .normalize('NFKD') // split accented characters into their base characters and diacritical marks
        .replace(/[\u0300-\u036f]/g, '') // remove all the accents, which happen to be all in the \u03xx UNICODE block.
        .trim() // trim leading or trailing whitespace
        .toLowerCase() // convert to lowercase
        .replace(/[^a-z0-9 -]/g, '') // remove non-alphanumeric characters
        .replace(/\s+/g, '-') // replace spaces with hyphens
        .replace(/-+/g, '-'); // remove consecutive hyphens
    }
  }
}
</script>
