<template>
    <CModal
      :title="title"
      :color="color"
      size="lg"
      :show.sync="showModal"
    >
        <CForm>
            <CRow>
              <CCol md="3">
                <CInput
                  label="Ruta:"
                  :lazy="false"
                  :value.sync="$v.form.code.$model"
                  :isValid="checkIfValid('code')"
                  placeholder=""
                  autocomplete="given-code"
                  invalidFeedback="Este campo es requerido."
                />
              </CCol>
              <CCol md="9">
                <CInput
                  label="Nombre:"
                  :lazy="false"
                  :value.sync="$v.form.name.$model"
                  :isValid="checkIfValid('name')"
                  placeholder=""
                  autocomplete="given-name"
                  invalidFeedback="Este campo es requerido y debe ser mayor a 2 caracteres."
                />
              </CCol>
            </CRow>
        </CForm>
        <template #footer>
          <CButton @click="closeModal" color="secondary">Cancelar</CButton>
          <CButton @click="store" :color="color">Agregar</CButton>
        </template>
    </CModal>
</template>

<script>
import { validationMixin } from "vuelidate"
import { required, minLength, email, sameAs, helpers } from "vuelidate/lib/validators"
import ws from '../../services/customers';
import suppliers_ws from '../../services/suppliers';

export default {
  name: 'ChallengeSupplierModal',
  props: {

  },
  data () {
    return {
      showModal: false,
      title: "Agregar Vendedor",
      color: "info",
      isEdit: false,
      form: this.getEmptyForm(),
      submitted: false,
      fields: [
        {key: 'name', label: 'Nombre'},
        {key: 'actions', label: '', class: 'actions' }
      ]
    }
  },
  mounted: async function() {
    
  },
  methods: {
    addSupplierModal (challenge_id) { 
      this.showModal = true;
      this.color = "info";
      this.title = 'Agregar Vendedor';
      this.isEdit = false;
      this.submitted = false;

      this.form = {
          id: 0,
          challenge_id: challenge_id,
          code: '',
          name: ''
      }
    },
    store () {
      this.$v.$touch();

      if (this.isValid) {
        this.submitted = true;

        this.$emit("store", this.form );

        this.closeModal();
      }
    },
    closeModal () {
      this.showModal = false;

      this.form = this.getEmptyForm()
      this.submitted = false
      this.$v.$reset()
    },
    getEmptyForm () {
      return {
          id: 0,
          challenge_id: 0,
          code: '',
          name: ''
      }
    },
    checkIfValid (fieldName) {
      const field = this.$v.form[fieldName]
      if (!field.$dirty) {
        return null
      }
      
      return !(field.$invalid || field.$model === '')
    },
  },
  computed: {
    formString () { return JSON.stringify(this.form, null, 4) },
    isValid () { return !this.$v.form.$invalid },
    isDirty () { return this.$v.form.$anyDirty },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      name: {
        required,
        minLength: minLength(3)
      },
      code: {
        required,
        minLength: minLength(1)
      }
    }
  },
}
</script>