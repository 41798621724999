<template>
  <CCard>
    <CCardHeader>
      <slot name="header">
        <CIcon name="cil-https"/> Dinámica para Supervisores
        <div class="card-header-actions"><CButton color="info" size="sm" v-show="challenge.type == 'video'" @click="storeModal()">Nuevo Video</CButton></div>
      </slot>
    </CCardHeader>
    <CCardBody>
      <CRow v-if="challenge.type == 'boxes'">
        <CCol v-if="challenge.mechanic == 'total'">
          <b>{{(challenge.mechanic == 'total' ? 'Numero Total de Cajas Vendidas' : challenge.mechanic == 'ranges' ? 'Rangos de Cajas Vendidas' : 'Individual Por Cada Caja Vendida')}}</b>
          <br /><br />
          <CRow>
              <CCol md="4">
                <CInput
                    type="text"
                    name="image"
                    id="image"
                    label="Mínimo de cajas a vender:"
                    :lazy="false"
                    :value.sync="challenge.manager_awards[0].boxes"
                />
              </CCol>
          </CRow>
        </CCol>
        <CCol v-if="challenge.mechanic == 'ranges'">
          <b>{{(challenge.mechanic == 'total' ? 'Numero Total de Cajas Vendidas' : challenge.mechanic == 'ranges' ? 'Rangos de Cajas Vendidas' : 'Individual Por Cada Caja Vendida')}}</b>
          <br /><br />
          <CRow v-if="challenge.type == 'boxes' && (challenge.mechanic == 'ranges')" v-for="(range, index) in challenge.ranges" :key="'r_'+index">
            <CCol md="2">
              <CInput
                type="number"
                label="Desde (cajas):"
                :lazy="false"
                :value.sync="range.min"
                placeholder=""
              />
            </CCol>
            <CCol md="2">
              <CInput
                type="number"
                label="Hasta (cajas):"
                :lazy="false"
                :value.sync="range.max"
                placeholder=""
              />
            </CCol>
          </CRow>
        </CCol>
        <CCol v-if="challenge.mechanic == 'individual'">
          <b>{{(challenge.mechanic == 'total' ? 'Numero Total de Cajas Vendidas' : challenge.mechanic == 'ranges' ? 'Rangos de Cajas Vendidas' : 'Individual Por Cada Caja Vendida')}}</b>
          <br /><br />
          <CRow>
              <CCol md="4">
                <CInput
                    type="text"
                    name="image"
                    id="image"
                    label="Ganan cada que vendan (# cajas):"
                    :lazy="false"
                    :value.sync="challenge.manager_awards[0].boxes"
                />
              </CCol>
          </CRow>
        </CCol>
      </CRow>
      <CRow>
          <CCol md="12"><br />* Los premios se definirán en la pestaña correspondiente.</CCol>
      </CRow>
      <CRow>
        <CCol md="9"></CCol>
        <CCol md="3">
          <CButton style="float: right;" color="info" size="sm" @click="updateRules()">Guardar</CButton>
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>
</template>

<script>
import ws from '../../services/challenges';
import store from '../../store'
import router from '../../router/index'

export default {
  name: 'ChallengeManagerRules',
  components: {  },
  props: {
    items: Array,
    permissions: Array
  },
  data() {
    return {
      toasts: [],
      rate: 100,
      challenge: {
        'type': 'boxes'
      },
      averageOptions: [
        {label: '100% de Aciertos', value: 100},
        {label: '95% de Aciertos', value: 95},
        {label: '90% de Aciertos', value: 90},
        {label: '85% de Aciertos', value: 85},
        {label: '80% de Aciertos', value: 80},
        {label: '75% de Aciertos', value: 75},
        {label: '70% de Aciertos', value: 70},
        {label: '65% de Aciertos', value: 65},
        {label: '60% de Aciertos', value: 60},
        {label: '55% de Aciertos', value: 55},
        {label: '50% de Aciertos', value: 50},
      ],
      typeOptions: [
        {label: 'Monedero Electrónico', value: 'wallet'},
        {label: 'Boletos para Evento', value: 'ticket'},
        {label: 'Dinero en Efectivo', value: 'cash'},
        {label: 'Tarjeta de Regalo', value: 'card'},
        {label: 'Premio Físico', value: 'gift'},
        {label: 'Puntos para Eventos', value: 'event_points'},
        {label: 'Puntos para Certificados', value: 'card_points'},
        {label: 'Puntos para Premios', value: 'gift_points'},
        {label: 'Puntos para Monedero', value: 'wallet_points'}
      ]
    }
  },
  methods: {
    async loadChallenge (challenge) {
      this.challenge = challenge;
    },
    async updateRules () {
      this.$emit("update", { type: this.challenge.type, mechanic: this.challenge.mechanic, challenge_id: this.challenge.id, challenge: this.challenge, ranges: this.challenge.ranges } );
    },
    async changeRate () {
      let params = {challenge_id: this.challenge.id, rate: this.challenge.rate};

      let response = await ws.changeRate(params);
      
      if(response.type == "success"){
        
      }

      this.showToast(response.type, response.message);
    },
    storeModal (item) {
      this.$refs.challengeVideoModalRules.storeModal(this.challenge.id);
    },
    async storeProcess (data) {
      this.$emit("add", data );
    },
    deleteVideoModal (item) {
      this.$refs.deleteVideoModal.show("Eliminar Video", item.id, "https://www.youtube.com/watch?v="+item.youtube_reference);
    },
    async deleteProcess(id) {
      let response = await ws.deleteVideo({video_id: id});
      
      if(response.type == "success"){
        this.$emit("remove", response.data );
      }

      this.showToast(response.type, response.message);
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    },
    slugify(str) {
      return String(str)
        .normalize('NFKD') // split accented characters into their base characters and diacritical marks
        .replace(/[\u0300-\u036f]/g, '') // remove all the accents, which happen to be all in the \u03xx UNICODE block.
        .trim() // trim leading or trailing whitespace
        .toLowerCase() // convert to lowercase
        .replace(/[^a-z0-9 -]/g, '') // remove non-alphanumeric characters
        .replace(/\s+/g, '-') // replace spaces with hyphens
        .replace(/-+/g, '-'); // remove consecutive hyphens
    }
  }
}
</script>
