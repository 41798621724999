<template>
  <CCard>
    <CCardHeader>
      <slot name="header">
        <CIcon name="cil-https"/> {{caption}}
        <div class="card-header-actions">
          <CButton color="info" size="sm" @click="storeModal()">Nueva Pregunta</CButton>
        </div>
      </slot>
    </CCardHeader>
    <CCardBody>
      <CDataTable
        :hover="hover"
        :striped="striped"
        :border="border"
        :small="small"
        :fixed="fixed"
        :items="items"
        :fields="fields"
        :items-per-page="small ? 10 : 5"
        :dark="dark"
        pagination
      >
        <template #question="{item}"><td class="">
          <span v-if="item.type == 'options' && item.options.length > 0" style="float: right;">
            <ul style="margin: 0px">
              <li v-for="option in item.options.split(',')" :style="item.correct_answer == option.trim() ? 'font-weight: bold;' : ''">{{option.trim()}}</li>
            </ul>
          </span>
          <span>{{item.question}}</span>
        </td></template>
        <template #type="{item}"><td class="w100 text-center"><span>{{item.type == "text" ? "Texto" : (item.type == "options" ? "Opciones" : (item.type == "file" ? "Archivo" : "Otra"))}}</span></td></template>
        <template #actions="{item}">
          <td class="table_actions b3">
            <CButton color="warning" size="sm" @click="updateModal(item)"><CIcon name="cil-pencil"/></CButton>
            <CButton color="danger" size="sm" @click="deleteModal(item)"><CIcon name="cil-trash"/></CButton>
          </td>
        </template>
      </CDataTable>
    </CCardBody>
    <QuestionModal ref="questionModal" :challenge="challenge" @store="storeProcess" @update="updateProcess"></QuestionModal>
    <DeleteModal ref="deleteModal" @delete="deleteProcess"></DeleteModal>
  </CCard>
</template>

<script>
import QuestionModal from './QuestionModal.vue'
import DeleteModal from '../global/DeleteModal.vue'
import ws from '../../services/challenges';
import store from '../../store'
import router from '../../router/index'

export default {
  name: 'ChallengeQuest',
  components: { QuestionModal, DeleteModal },
  props: {
    items: Array,
    permissions: Array,
    fields: {
      type: Array,
      default () {
        return [
          {key: 'question', label: 'Nombre'},
          {key: 'type', label: 'Tipo'},
          {key: 'actions', label: 'Acciones', class: 'actions' },
        ]
      }
    },
    caption: {
      type: String,
      default: 'Table'
    },
    challenge: {
      type: Object,
      default: 0
    },
    hover: Boolean,
    striped: Boolean,
    border: Boolean,
    small: Boolean,
    fixed: Boolean,
    dark: Boolean
  },
  data() {
    return {
      toasts: [],
    }
  },
  methods: {
    storeModal () {
      this.$refs.questionModal.storeModal();
    },
    updateModal (item) {
      this.$refs.questionModal.updateModal(item);
    },
    deleteModal (item) {
      this.$refs.deleteModal.show("Eliminar Pregunta", item.id, item.name);
    },
    async storeProcess(data) {
      let response = await ws.storeQuestion(data);
      
      if(response.type == "success"){
        this.$emit("refresh", response.data );
      }

      this.showToast(response.type, response.message);
    },
    async updateProcess(data) {
      let response = await ws.updateQuestion(data);

      if(response.type == "success"){
        this.$emit("refresh", response.data );
      }

      this.showToast(response.type, response.message);
    },
    async deleteProcess(id) {
      let response = await ws.deleteQuestion({id: id});
      
      if(response.type == "success"){
        this.$emit("refresh", response.data );
      }

      this.showToast(response.type, response.message);
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    },
    slugify(str) {
      return String(str)
        .normalize('NFKD') // split accented characters into their base characters and diacritical marks
        .replace(/[\u0300-\u036f]/g, '') // remove all the accents, which happen to be all in the \u03xx UNICODE block.
        .trim() // trim leading or trailing whitespace
        .toLowerCase() // convert to lowercase
        .replace(/[^a-z0-9 -]/g, '') // remove non-alphanumeric characters
        .replace(/\s+/g, '-') // replace spaces with hyphens
        .replace(/-+/g, '-'); // remove consecutive hyphens
    }
  }
}
</script>
